.cls-1{
    fill:#0057ff;
}
.cls-2{
    fill:#0d8617;
}
.cls-3{
    fill:#fa0;
}
.cls-4 {
    fill:none;stroke:#000;
    stroke-miterlimit:10;
}