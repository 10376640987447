@tailwind base;
@tailwind components;
@tailwind utilities;

.MuiAutocomplete-popper {
    z-index: 99999999!important;
}

.MuiPopover-root.MuiMenu-root.MuiModal-root {
    z-index: 99999999!important;
}

